<template>
  <div class="w-full flex flex-col border bg-white rounded min-h-96">
    <div class="flex space-x-8 bg-white min-h-96 p-4">
      <div class="flex flex-col mx-5">
        <router-link to="/settings/general/rating-scales" class="flex items-center -mx-5 space-x-1">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-blue-lighter"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <p class="text-blue-lighter text-sm">Bewertungsskala</p>
        </router-link>
        <p class="text-gray-500 p-0">
          Definieren Sie Ihre Bewertungsskala für die Mitarbeitergespräche
        </p>
      </div>

      <div class="flex flex-col mx-5">
        <router-link to="/settings/general/pdf-settings" class="flex items-center -mx-5 space-x-1">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-blue-lighter"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <p class="text-blue-lighter text-sm">PDF Einstellungen</p>
        </router-link>
        <p class="text-gray-500 p-0">Definieren Sie Ihr PDF Format für die verschiedenen Module</p>
      </div>

      <div class="flex flex-col mx-5">
        <router-link
          to="/settings/general/notification-settings"
          class="flex items-center -mx-5 space-x-1"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-blue-lighter"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </div>
          <p class="text-blue-lighter text-sm">Notification Einstellungen</p>
        </router-link>
        <p class="text-gray-500 p-0">
          Definieren Sie Ihr Notification für die verschiedenen Module
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'general',
  components: {},
  data() {
    return {
      breadcrumbs: {
        title: [{ link: '/settings', crumb: 'Allgemeine Einstellungen' }, { crumb: 'Stammdaten' }],
        pageTitle: 'Stammdaten',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>

<style></style>
